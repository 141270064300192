import { Component, Button } from 'substance'

class Pager extends Component {

  render($$) {
    let total = this.props.total
    let page = this.props.page
    let perPage = this.props.perPage
    let items = this.props.items
    let currentSize = items.length

    let isSinglePage = currentSize < perPage & page === 1
    let isFirstPage = page === 1
    let isLastPage = currentSize < perPage

    let el = $$('div').addClass('sc-pager')

    if(!isSinglePage) {
      let firstBtnDisabled = false;
      let prevBtnDisabled = false;
      let nextBtnDisabled = false;
      let lastBtnDisabled = false;

      if(isFirstPage) {
        firstBtnDisabled = true;
        prevBtnDisabled = true;
      }

      if(isLastPage) {
        nextBtnDisabled = true;
        lastBtnDisabled = true;
      }

      let firstBtn = $$(Button, {disabled: firstBtnDisabled})
        .addClass('se-first').on('click', this.goToFirstPage).append('<<')
      let prevBtn = $$(Button, {disabled: prevBtnDisabled})
        .addClass('se-prev').on('click', this.goToPrevPage).append('<')
      let nextBtn = $$(Button, {disabled: nextBtnDisabled})
        .addClass('se-next').on('click', this.goToNextPage).append('>')
      let lastBtn = $$(Button, {disabled: lastBtnDisabled})
        .addClass('se-last').on('click', this.goToLastPage).append('>>')

      el.append(
        prevBtn,
        nextBtn
      )
    }

    return el
  }

  goToFirstPage(e) {
    e.preventDefault()
    this.send('changePage', 1)
  }

  goToPrevPage(e) {
    e.preventDefault()
    let page = this.props.page
    this.send('changePage', page - 1)
  }

  goToNextPage(e) {
    e.preventDefault()
    let page = this.props.page
    this.send('changePage', page + 1)
  }

  goToLastPage(e) {
    e.preventDefault()
    let total = this.props.total
    let perPage = this.props.perPage
    let lastPage = Math.floor(total/perPage)
    this.send('changePage', lastPage + 1)
  }

}

export default Pager
