import { Component } from 'substance'

class DateTimeFilter extends Component {

  getInitialState() {
    let filter = this.props.filter
    return {
      value: filter ? filter.value : ''
    }
  }

  render($$) {
    let placeholder = this.props.placeholder
    let value = this.state.value

    let el = $$('div').addClass('sc-date-time-input-filter')

    let input = $$('input')
      .attr({type: 'datetime-local', placeholder: placeholder, value: value})
      .ref('input')
      .on('change', this.onQuery)

    el.append(input)

    return el
  }

  onQuery(e) {
    e.preventDefault()
    this.query()
  }

  clear(e) {
    e.preventDefault()
    this.extendState({
      value: ''
    })
    this.query()
  }

  query() {
    let property = this.props.name
    let multi = this.props.multi
    let op = this.props.op
    let value = this.refs.input.val()
    let filter = {
      name: property,
      multi: multi,
      op: op,
      value: value
    }
    this.send('filterList', property, filter)
  }
}

export default DateTimeFilter
