import HeaderPackage from '../header/package'
import WelcomePackage from '../welcome/package'
import ManagerPackage from '../manager/package'
import UploadPackage from '../upload/package'
import UserPackage from '../users/package'
import PagerPackage from '../pager/package'

export default {
  name: 'reports',
  configure: function(config) {
    // config.import(Note);
    // config.import(Dashboard);
    config.import(HeaderPackage)
    // //config.import(LoaderPackage);
    // config.import(NotificationPackage);
    // config.import(CollaboratorsPackage);
    config.import(WelcomePackage)
    config.import(ManagerPackage)
    config.import(UploadPackage)
    config.import(UserPackage)
    config.import(PagerPackage)
  }
}
