import AbstractApplication from '../common/AbstractApplication'
import ReportsRouter from './ReportsRouter'
import forEach from 'lodash/forEach'

/*
  Reports Application component.
*/
class Reports extends AbstractApplication {

  constructor(parent, props) {
    super(parent, props)

    if (!props.configurator) {
      throw new Error("'configurator' is required")
    }

    this.configurator = props.configurator
    this.config = this.configurator.getAppConfig()
    this.authenticationClient = this.configurator.getAuthenticationClient()
    this.fileClient = this.configurator.getFileClient(this.authenticationClient)
    this.reportClient = this.configurator.getReportClient(this.authenticationClient)
    this.componentRegistry = this.configurator.getComponentRegistry()
    this.iconProvider = this.configurator.getIconProvider()
    this.labelProvider = this.configurator.getLabelProvider()

    let pages = this.configurator.getPages()
    forEach(pages, function(page) {
      this.addPage(page, this.componentRegistry.get(page))
    }.bind(this))

    this.handleActions({
      'navigate': this.navigate,
      'home': this._home
    })
  }

  getChildContext() {
    return {
      config: this.config,
      configurator: this.configurator,
      authenticationClient: this.authenticationClient,
      componentRegistry: this.componentRegistry,
      fileClient: this.fileClient,
      reportClient: this.reportClient,
      urlHelper: this.router,
      iconProvider: this.iconProvider,
      labelProvider: this.labelProvider
    }
  }

  getDefaultPage() {
    return 'manager'
  }

  getLoginPage() {
    return 'welcome'
  }

  getRouter() {
    return new ReportsRouter(this);
  }

  _onAuthentication(route, session) {
    if(!session) {
      route.page = this.getLoginPage()
    } else if (!session.user.name) {
      route.page = 'entername'
    }

    return route
  }

  _home() {
    this.navigate({
      page: this.getDefaultPage()
    })
  }
}

export default Reports