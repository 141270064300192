import { Configurator } from 'substance'

/*
  Reports configurator.
*/

class ReportsConfigurator extends Configurator {

  constructor(...args) {
    super(...args)
    // Extend config
    this.config.configurators = {}
    this.config.pages = []
    this.config.menu = []
  }

  /*
    Set app config
  */
  setAppConfig(config) {
    this.config.app = config
  }

  /*
    Get app config
  */
  getAppConfig() {
    return this.config.app
  }

  /*
    Set Authentication Server url
  */
  setAuthenticationServerUrl(url) {
    this.config.authenticationServerUrl = url
  }

  /*
    Set Report Server url
  */
  setReportServerUrl(url) {
    this.config.reportServerUrl = url
  }

  /*
    Set Report Client class
  */
  setReportClient(ReportClientClass) {
    this.config.ReportClientClass = ReportClientClass
  }

  /*
    Get Report Client instance
  */
  getReportClient(authClient) {
    let ReportClientClass = this.config.ReportClientClass;
    return new ReportClientClass({httpUrl: this.config.reportServerUrl, authClient: authClient})
  }

  /*
    Set Authentication Client class
  */
  setAuthenticationClient(AuthenticationClientClass) {
    this.config.AuthenticationClientClass = AuthenticationClientClass
  }

  /*
    Get Authentication Client instance
  */
  getAuthenticationClient() {
    let AuthenticationClientClass = this.config.AuthenticationClientClass
    return new AuthenticationClientClass({httpUrl: this.config.authenticationServerUrl})
  }

  /*
    Set File Server url
  */
  setFileServerUrl(url) {
    this.config.fileServerUrl = url
  }

  /*
    Set File Client class
  */
  setFileClient(fileClient) {
    this.config.fileClient = fileClient
  }

  /*
    Get File Client instance
  */
  getFileClient(authClient) {
    let FileClientClass = this.config.fileClient
    return new FileClientClass({httpUrl: this.config.fileServerUrl, authClient: authClient})
  }

  addPage(pageName, component) {
    this.addComponent(pageName, component)
    this.config.pages.push(pageName)
  }

  getPages() {
    return this.config.pages
  }

  setMenuItems(menuItems) {
    this.config.menu = menuItems
  }

  getMenuItems() {
    return this.config.menu
  }

}

export default ReportsConfigurator
