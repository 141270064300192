import { Router } from 'substance'

class ReportsRouter extends Router{
  constructor(app) {
    super()
    this.app = app
  }

  getRoute() {
    let routerString = this.getRouteString()
    return Router.routeStringToObject(routerString)
  }
}

export default ReportsRouter
