import { substanceGlobals } from 'substance'
import Package from './package'
import Reports from '../../packages/reports/Reports'
import ReportsConfigurator from '../../packages/reports/ReportsConfigurator'

substanceGlobals.DEBUG_RENDERING = true
let configurator = new ReportsConfigurator().import(Package)

window.onload = function() {
  window.app = Reports.mount({
    configurator: configurator
  }, document.body)
}
