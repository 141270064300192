import { Component, Layout } from 'substance'
import UsersList from './UsersList'

class Users extends Component {

  render($$) {
    let el = $$('div').addClass('sc-users sc-container')

    let header = this.renderHeader($$)
    el.append(header)
    
    let layout = $$(Layout, {
      width: 'full',
      textAlign: 'left',
      noPadding: true
    })

    layout.append(
      $$(UsersList, this.props).ref('list')
    )

    el.append(layout)

    return el
  }

  renderHeader($$) {
    let headerActions = {
      'manager': 'Отчеты',
      'upload': 'Загрузка'
    }
    if(this.props.userSession.user.super) {
      headerActions['users'] = 'Пользователи';
    }

    let Header = this.getComponent('header')
    return $$(Header, {actions: headerActions})
  }
}

export default Users