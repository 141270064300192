import { Component, SubstanceError as Err } from 'substance'
import InputFilter from './InputFilter'
import DateTimeFilter from './DateTimeFilter'

class Filters extends Component {

  didMount() {
    //this._loadFilterValues()
  }

  getInitialState() {
    return {
      selects: [],
      filterOptions: {}
    }
  }

  render($$) {
    let el = $$('div').addClass('sc-filters')

    let quick_find = $$('div').addClass('se-quick-find').append(
      $$(InputFilter, {name: 'name', op: '~~*', type: 'text', placeholder: 'Поиск по имени', filter: this.props.name})
    )

    var date_filters = $$('div').addClass('se-date-filters').append(
      $$(DateTimeFilter, {name: 'date@1', op: '>=', placeholder: 'С... (YYYY-MM-DDTHH:mm)', filter: this.props['date@1']}),
      " - ",
      $$(DateTimeFilter, {name: 'date@2', op: '<=', placeholder: 'По... (YYYY-MM-DDTHH:mm)', filter: this.props['date@2']})
    );

    el.append(
      quick_find,
      date_filters
    );

    return el;
  }

  _loadFilterValues() {
    let reportClient = this.context.reportClient
    let selects = this.state.selects

    reportClient.getFilterValues(selects, 'reports', function(err, options) {
      if (err) {
        this.setState({
          error: new Err('Filters.LoadingError', {
            message: 'Filters could not be loaded.',
            cause: err
          })
        })
        console.error('ERROR', err);
        return;
      }

      this.extendState({
        filterOptions: options
      })
    }.bind(this))
  }
}

export default Filters
