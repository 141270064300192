import ReportsPackage from '../../packages/reports/package'
import AuthenticationClient from './AuthenticationClient'
import FileClient from './FileClient'
import ReportClient from './ReportClient'

let appConfig = '{"protocol":"https","host":"reports.podari-zhizn.ru","port":443}'
//let appConfig = '{"protocol":"http","host":"reports.d4s.io","port":5000}'
//let appConfig = '{"protocol":"http","host":"localhost:5001","port":5001}'
appConfig = JSON.parse(appConfig)

export default {
  name: 'reports-app',
  configure: function(config) {
    // Use the default report package
    config.import(ReportsPackage)

    config.setAppConfig({
      protocol: appConfig.protocol,
      host: appConfig.host,
      port: appConfig.port
    })

    // Define Authentication Client
    config.setAuthenticationServerUrl(appConfig.protocol + '://'+appConfig.host+'/api/auth/')
    config.setAuthenticationClient(AuthenticationClient)
    // Define File Client
    config.setFileServerUrl(appConfig.protocol + '://'+appConfig.host+'/api/files/')
    config.setFileClient(FileClient)
    // Define Report Client
    config.setReportServerUrl(appConfig.protocol + '://'+appConfig.host+'/api/')
    config.setReportClient(ReportClient)

  }
}