/*
  HTTP client for talking with DonationServer
*/
class ReportClient {
  constructor(config) {
    this.config = config
    this.authClient = config.authClient
  }

  request(method, url, data, cb) {
    let request = new XMLHttpRequest();
    request.open(method, url, true)
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    request.setRequestHeader('x-access-token', this.authClient.getSessionToken())
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        let res = request.responseText;
        if(isJson(res)) res = JSON.parse(res);
        cb(null, res);
      } else {
        return cb(new Error('Request failed. Returned status: ' + request.status))
      }
    }

    if (data) {
      request.send(JSON.stringify(data))
    } else {
      request.send()
    }
  }

  /*
    Add new report
  */
  createReport(report, cb) {
    this.request('POST', this.config.httpUrl + 'reports', report, cb)
  }

  /*
    Get a report from the server
  */
  getReport(reportId, cb) {
    this.request('GET', this.config.httpUrl + 'reports/' + reportId, null, cb)
  }

  /*
    Update a user
  */
  updateReport(reportId, data, cb) {
    this.request('PUT', this.config.httpUrl + 'reports/' + reportId, data, cb)
  }

  /*
    Remove a report from the server
  */
  removeReport(reportId, cb) {
    this.request('DELETE', this.config.httpUrl + 'reports/' + reportId, null, cb)
  }

  /*
    Get reports from the server
  */
  listReports(filters, options, cb) {
    let filtersRequest = encodeURIComponent(JSON.stringify(filters))
    let optionsRequest = encodeURIComponent(JSON.stringify(options))
    this.request('GET', this.config.httpUrl + 'reports?filters=' + filtersRequest + '&options=' + optionsRequest, null, cb)
  }


  /*
    Get distinct values for filters from the server
  */

  // this.getFilterValues = function(filters, source, cb) {
  //   var filtersRequest = encodeURIComponent(JSON.stringify(filters));
  //   this._this.request('GET', this.config.httpUrl + 'filters?filters=' + filtersRequest + '&source=' + source, null, cb);
  // };

  /*
    Create a user
  */
  createUser(data, cb) {
    this.request('POST', this.config.httpUrl + 'users', data, cb)
  }

  /*
    Update a user
  */
  updateUser(userId, data, cb) {
    this.request('PUT', this.config.httpUrl + 'users/' + userId, data, cb)
  }

  /*
    Get users from the server
  */
  listUsers(filters, options, cb) {
    let filtersRequest = encodeURIComponent(JSON.stringify(filters))
    let optionsRequest = encodeURIComponent(JSON.stringify(options))
    this.request('GET', this.config.httpUrl + 'users?filters=' + filtersRequest + '&options=' + optionsRequest, null, cb)
  }

  getFilterValues(filters, source, cb) {
    let filtersRequest = encodeURIComponent(JSON.stringify(filters))
    this.request('GET', this.config.httpUrl + 'filters?filters=' + filtersRequest + '&source=' + source, null, cb)
  }

}

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export default ReportClient
