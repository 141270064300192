import { Component, Layout, SubstanceError as Err } from 'substance'

class Upload extends Component {

  render($$) {
    let el = $$('div').addClass('sc-upload sc-container')

    let header = this.renderHeader($$)
    el.append(header)
    
    let layout = $$(Layout, {
      width: 'full',
      textAlign: 'center',
      noPadding: false
    }).addClass('se-upload');

    let input = $$('input').attr({'type': 'file', 'id': 'upload'})
      .ref('input')
      .on('change', this.onFileSelect)

    let message = $$('div').addClass('message').append(
      'Ваш файл загружен на сервер и в ближайшее время будет обработан'
    )

    if(this.state.done) {
      layout.append(message)
    } else {
      layout.append(input)
    }

    el.append(layout)

    return el
  }

  renderHeader($$) {
    let headerActions = {
      'manager': 'Отчеты',
      'upload': 'Загрузка'
    }
    if(this.props.userSession.user.super) {
      headerActions['users'] = 'Пользователи';
    }

    let Header = this.getComponent('header')
    return $$(Header, {actions: headerActions})
  }

  onFileSelect(e) {
    let files = e.currentTarget.files
    let fileClient = this.context.fileClient
    let file = files[0]
    fileClient.uploadFile(file, function(err) {
      if (err) {
        this.setState({
          error: new Err('Feed.LoadingError', {
            message: 'Data could not be loaded.',
            cause: err
          })
        })
        console.error('ERROR', err)
        return
      }

      console.log('OK')
      this.setState({done: true})
    }.bind(this))
  }
}

export default Upload